import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'farmer-role',
    templateUrl: './farmer.component.html'
})
export class FarmerRoleComponent {
}

