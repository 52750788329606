import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'standarduser-role',
  templateUrl: './standarduser.component.html'
})
export class StandardUserRoleComponent {
}

