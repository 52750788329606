import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'driver-role',
  templateUrl: './driver.component.html'
})
export class DriverRoleComponent {
}

