import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'administrator-role',
  templateUrl: './administrator.component.html'
})
export class AdministratorRoleComponent {
}

