import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'wholesaler-role',
  templateUrl: './wholesaler.component.html'
})
export class WholeSalerRoleComponent {
}

