import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'government-role',
  templateUrl: './government.component.html'
})
export class GovernmentRoleComponent {
}

